<template>
    <div class="bg-warp">
        <div class="head-title">
            <div class="line"><span @click="hotLine"><a-icon style="margin-right:8px" class="icon" type="phone"/>服务热线</span> <span @click="downloadBrowser('GoogleChrome88.exe')" style="margin-left:10px;"> <a-icon type="chrome" />下载谷歌浏览器</span></div>
            <div><span>© 软控股份有限公司</span></div>
        </div>
        <div class="login_form_wp">
            <div class="login-logo"></div>
            <div class="login-title">
                软控协同办公平台
            </div>
            <a-form id="formLogin" ref="formLogin" :form="form" class="user-layout-login">
                <a-form-item>
                    <a-input
                        v-decorator="['username',validatorRules.username,{ validator: handleUsernameOrEmail }]"
                        size="large"
                        type="text"
                        placeholder="请输入登录账号"
                    >
                        <a-icon slot="prefix" type="user" style="color:#BC0039" />
                    </a-input>
                </a-form-item>

                <a-form-item>
                    <a-input
                        v-decorator="['password',validatorRules.password]"
                        size="large"
                        type="password"
                        autocomplete="false"
                        placeholder="请输入登录密码"
                    >
                        <a-icon slot="prefix" type="lock" style="color:#BC0039" />
                    </a-input>
                </a-form-item>
                <a-row :gutter="4">
                    <a-col :span="16">
                        <a-form-item>
                            <a-input
                                v-decorator="['inputCode',validatorRules.inputCode]"
                                size="large"
                                type="text"
                                @change="inputCodeChange"
                                placeholder="请输入验证码"
                            >
                                <a-icon  slot="prefix" type="smile" style="color:#BC0039" />
                            </a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :span="8" style="text-align: right" >
                        <img v-if="requestCodeSuccess" style="margin-top: 2px;" :src="randCodeImage" @click="handleChangeCheckCode">
                        <img v-else style="margin-top: 2px;" src="~@/assets/checkcode.png" @click="handleChangeCheckCode">
                    </a-col>
                </a-row>

                <a-form-item class="forge-item">
                  <a-checkbox v-decorator="['rememberMe', {initialValue: true, valuePropName: 'checked'}]" >自动登录</a-checkbox>
                  <span class="forge-password" style="float: right;" @click="$router.push('/alteration')">
                    忘记密码
                  </span>
                </a-form-item>

                <div class="login_btn_wp">
                    <a-button
                        size="large"
                        type="primary"
                        html-type="submit"
                        class="login-button"
                        :loading="loginBtn"
                        :disabled="loginBtn"
                        @click.stop.prevent="handleSubmit"
                    >登录</a-button>
                </div>
            </a-form>
        </div>
        <div class="downLoad"></div>
        <server-line ref="lineForm" />
    </div>
</template>
<script>
import { mapActions } from 'vuex'
import { getAction, getFileAccessHttpUrl } from '@/api/manage'
import Vue from 'vue'
import { ACCESS_TOKEN, USER_INFO, H3, REMEMBER_ME } from '@/store/mutation-types'
import serverLine from '@/views/serverLine'
import { setTimeout } from 'timers';

export default {
  components: {serverLine},
  data() {
    return {
      form: this.$form.createForm(this),
      loginBtn: false,
      currdatetime:'',
      randCodeImage:'',
      requestCodeSuccess:false,
      inputCodeContent:"",
      validatorRules: {
        username: { rules: [{ required: true, message: '请输入用户名!' }, { validator: this.handleUsernameOrEmail }] },
        password: { rules: [{ required: true, message: '请输入密码!', validator: 'click' }] },
        captcha: { rule: [{ required: true, message: '请输入验证码!' }] },
        inputCode: { rules: [{ required: true, message: '请输入验证码!' }] }
      },
    };
  },
  created () {
    const that = this
    setTimeout(()=> {
      that.handleChangeCheckCode()
    }, 500)
    this.currdatetime = new Date().getTime()
    Vue.ls.remove(ACCESS_TOKEN)
  },
  methods: {
    hotLine() {
      console.log('aaa')
      this.$refs.lineForm.open()
    },
    downloadBrowser(text){
      if(!text){
        this.$message.warning("未知的文件")
        return;
      }
      if(text.indexOf(",")>0){
        text = text.substring(0,text.indexOf(","))
      }
      let url = getFileAccessHttpUrl(text)
      window.open(url);
    },
    ...mapActions([ 'Login']),
      // handler
    handleUsernameOrEmail(rule, value, callback) {
      const regex = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/
      if (regex.test(value)) {
        this.loginType = 0
      } else {
        this.loginType = 1
      }
      callback()
    },
    handleSubmit(){
      let that = this
      let loginParams = {};
      that.loginBtn = true;
      that.form.validateFields([ 'username', 'password','inputCode','rememberMe' ],{ force: true }, (err, values) => {
        console.log('values: ', values);
        if (!err) {
          loginParams.username = values.username
          loginParams.password = values.password
          loginParams.captcha = that.inputCodeContent
          loginParams.checkKey = that.currdatetime
          Vue.ls.set(REMEMBER_ME, values.rememberMe , 7 * 24 * 60 * 60 * 1000)
          console.log('登录参数', loginParams)
          that.Login(loginParams).then((res) => {
            console.log('res: ', res)
            that.loginSuccess()
            that.getH3Login({username: values.username, password: values.password})
          }).catch((err) => {
            that.requestFailed(err);
          });
        }
        else {
            that.loginBtn = false
        }
      } ) 
    },
    requestFailed(err) {
      this.$notification[ 'error' ]({
          message: '登录失败',
          description: ((err.response || {}).data || {}).message || err.message || "请求出现错误，请稍后再试",
          duration: 4,
        });
        this.loginBtn = false;
    },
    inputCodeChange(e){
      this.inputCodeContent = e.target.value
    },
    validateInputCode(rule,value,callback){
      if(!value || this.verifiedCode==this.inputCodeContent){
         callback();
      }else{
        callback("您输入的验证码不正确!");
      }
    },
    loginSuccess () {
        this.$router.push({ path: "/home" }).catch(()=>{
          console.log('登录跳转首页出错,这个错误从哪里来的')
        })
        // this.$notification.success({
        //   message: '欢迎',
        //   // description: `${timeFix()}，欢迎回来`,
        // });
    },
    getH3Login() {
      let username = Vue.ls.get(USER_INFO).username
      console.log('workNo: ', username);
      getAction('/mesnac/DD/getH3URLDD', {username: username}).then(res => {
        if(res.success) {
          Vue.ls.set(H3, res.result , 7 * 24 * 60 * 60 * 1000)
        } else {
          Vue.ls.set(H3, '' , 7 * 24 * 60 * 60 * 1000)
        }
      })
    },
    handleChangeCheckCode(){
        this.currdatetime = new Date().getTime();
        getAction(`/mesnac/sys/randomImage/${this.currdatetime}`).then(res=>{
          if(res.success){
            this.randCodeImage = res.result
            this.requestCodeSuccess=true
          }else{
            this.$message.error(res.message)
            this.requestCodeSuccess=false
          }
        }).catch(()=>{
          this.requestCodeSuccess=false
        })
      },
    },
};
</script>
<style lang="less" scoped>
.bg-warp {
  position: relative;
  width: 100%;
  height: 100%;
  font-family: "Source Han Sans CN";
}
.bg-warp::before{
    content: "";
    position: absolute;
    background: url("~@/assets/img/login-bg.png") no-repeat bottom center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    z-index: -1;
}
.downLoad{
  margin-left: 70px;
  font-size: 16px;
  span {
    color: #2c3e50;
    cursor: pointer;
  }
}
.head-title {
  padding-top: 10px;
  margin: 0px 50px;
  display: flex;
  justify-content: space-between;
  .line{
    cursor: pointer;
  }
}
.login_form_wp {
    width:28%;
    // margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform:translate(-50%, -50%);
    // margin-top: 100px;
    border-radius: 20px;
    text-align: center;
    font-family: Roboto;
    border: 1px solid rgba(188, 0, 57, 100);
    .login-logo{
      margin: 30px auto;
      width: 162px;
      height: 26px;
      background: url("~@/assets/img/login-logo.png") no-repeat top center / contain;
    }
    .login-title{
        color: rgba(188, 0, 57, 100);
        font-size: 33px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 40px;
        // font-family: 方正综艺体-标准;
    }
}
.user-layout-login {
    margin: 30px 95px;
    .forge-item{
      text-align: left;
      font-size: 16px;
      /deep/ .ant-form-item-children .ant-checkbox-wrapper span{
        font-size: 16px;
        font-weight: 600;
      }
      /deep/.ant-checkbox-input:focus + .ant-checkbox-inner {
          border-color: #bc0039;
      }
      /deep/ .ant-checkbox-checked::after{
        border-color: #bc0039;
      }
      /deep/.ant-checkbox-checked .ant-checkbox-inner {
          background-color: #bc0039;
          border-color: #bc0039;
      }
      .forge-password{
        cursor: pointer;
        font-size: 0.833vw;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.65);
        &:hover{
          color: #bc0039;
        }
      }
    }
    /deep/ .ant-input{
        border-color:rgba(188, 0, 57, 37);
        // color: rgba(188, 0, 57, 37);
        ::-webkit-input-placeholder {
            color: rgba(188, 0, 57, 37);
        }
    }
  label {
    font-size: 14px;
  }

  .login_btn_wp {
    transition: 0.3s;
    .login-button{
      width: 360px;
      height: 55px;
      background-color: rgba(188, 0, 57, 100);
      color: rgba(255, 255, 255, 100);
      border: 1px solid rgba(188, 0, 57, 37);
      border-radius: 10px;
      font-size: 22px;
      text-align: center;
    }
    &:hover {
      transform: translateY(-.17857rem);
      filter: drop-shadow(4px 4px 5px #6e7ff3);
    }
  }
}
</style>


